import { uniq } from "lodash";
import { AdRevenueMethod, ProvidedBannerType } from "@/shared/models";
import {
  AdRoasFilter,
  ArpdauFilter,
  ArpuFilter,
  CalendarPerMinFilter,
  CashCountryFilter,
  CohortAnalysisFilter,
  CohortPerMinFilter,
  CpmFilter,
  MetricsFilter,
  PricedRevenueFilter,
  ReportDataSource,
  ReportFilter,
  ReportMetricType,
  UaMainMetricsOverviewFilter,
  MetricsConstructorFilter,
  PredefinedMetricModel,
  UserMetricModel,
  BasicMetricType,
  MetricType,
  RevenueMetricFilterModel,
} from "../models";

export const getReportDataSources = (report: ReportFilter) => {
  return {
    AD_ROAS_COUNTRY: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.STORES_REVENUE,
      ...((report as AdRoasFilter).adRevenueMethod === AdRevenueMethod.PRICED &&
      (report as AdRoasFilter).providedBanner === ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
      ...((report as AdRoasFilter).adRevenueMethod === AdRevenueMethod.AVERAGE
        ? [ReportDataSource.NETWORKS_REVENUE]
        : []),
    ],
    AD_ROAS_NETWORK: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_SPEND,
      ...((report as AdRoasFilter).adRevenueMethod === AdRevenueMethod.AVERAGE
        ? [ReportDataSource.STORES_REVENUE, ReportDataSource.NETWORKS_REVENUE]
        : []),
      ...((report as AdRoasFilter).providedBanner ===
      ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
      ...((report as AdRoasFilter).adRevenueMethod === AdRevenueMethod.PRICED &&
      (!(report as AdRoasFilter).revenue?.length ||
        (report as AdRoasFilter).revenue.includes("IAPS"))
        ? [ReportDataSource.STORES_REVENUE]
        : []),
    ],
    ARPDAU: () => [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
      ...((report as ArpdauFilter).adRevenueMethod === AdRevenueMethod.PRICED &&
      (report as ArpdauFilter).providedBanner === ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
      ...((report as ArpdauFilter).adRevenueMethod === AdRevenueMethod.AVERAGE
        ? [ReportDataSource.NETWORKS_REVENUE]
        : []),
    ],
    ARPDAU_SIMPLE: () => [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    ARPU: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ...((report as ArpuFilter).providedBanner === ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
      ...((report as ArpuFilter).adRevenueMethod === AdRevenueMethod.AVERAGE
        ? [ReportDataSource.STORES_REVENUE, ReportDataSource.NETWORKS_REVENUE]
        : []),
      ...((report as ArpuFilter).adRevenueMethod === AdRevenueMethod.PRICED &&
      (!(report as ArpuFilter).revenue?.length ||
        (report as ArpuFilter).revenue.includes("IAPS"))
        ? [ReportDataSource.STORES_REVENUE]
        : []),
    ],
    CALENDAR_CPM: () => [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ...((report as CpmFilter).adRevenueMethod === AdRevenueMethod.PRICED &&
      (report as CpmFilter).providedBanner === ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
      ...((report as CpmFilter).adRevenueMethod === AdRevenueMethod.AVERAGE
        ? [ReportDataSource.NETWORKS_REVENUE]
        : []),
    ],
    CALENDAR_CTR: () => [ReportDataSource.ADJUST_RAW_EVENTS],
    CALENDAR_PER_MIN: () => [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ...((report as CalendarPerMinFilter).providedBanner ===
      ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
    ],
    CASH_COUNTRY: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
      ...((report as CashCountryFilter).adRevenueMethod ===
        AdRevenueMethod.PRICED &&
      (report as CashCountryFilter).providedBanner ===
        ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
      ...((report as CashCountryFilter).adRevenueMethod ===
      AdRevenueMethod.AVERAGE
        ? [ReportDataSource.NETWORKS_REVENUE]
        : []),
    ],
    CASH_GAMING: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_INSTALLS,
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.STORES_REVENUE,
    ],
    COHORT_ANALYSIS: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.NETWORKS_SPEND,
      ...(!(report as CohortAnalysisFilter).revenue?.length ||
      (report as CohortAnalysisFilter).revenue.includes("IAPS")
        ? [ReportDataSource.STORES_REVENUE]
        : []),
    ],
    COHORT_CPM: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
      ...((report as ArpuFilter).providedBanner === ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
    ],
    COHORT_CTR: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
    ],
    COHORT_PER_MIN: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ...((report as CohortPerMinFilter).providedBanner ===
      ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
    ],
    DIFF_INSTALLS_STORES: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.STORES_INSTALLS,
    ],
    EVENTS_COST: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_SPEND,
    ],
    EVENTS_SUMMARY: () => [ReportDataSource.ADJUST_RAW_EVENTS],
    FILL_RATE: () => [ReportDataSource.ADJUST_RAW_EVENTS],
    MEASURES_LITE: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
    ],
    METRICS: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ...((report as MetricsFilter).adRevenueMethod === AdRevenueMethod.AVERAGE
        ? [ReportDataSource.STORES_REVENUE, ReportDataSource.NETWORKS_REVENUE]
        : []),
      ...((report as MetricsFilter).adRevenueMethod === AdRevenueMethod.PRICED
        ? [ReportDataSource.STORES_REVENUE]
        : []),
    ],
    METRICS_SPEND: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_SPEND,
      ...((report as MetricsFilter).adRevenueMethod === AdRevenueMethod.AVERAGE
        ? [ReportDataSource.NETWORKS_REVENUE]
        : []),
    ],
    PAYING_USERS_CONVERSION: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
      ReportDataSource.NETWORKS_SPEND,
    ],
    PRICED_REVENUE: () => [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
      ...((report as PricedRevenueFilter).providedBanner ===
      ProvidedBannerType.FIREBASE
        ? [ReportDataSource.FIREBASE_RAW_EVENTS]
        : []),
    ],
    PROFIT: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    RETURN_RATE: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
    ],
    REVENUE: () => [
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    SKAD: () => [
      ReportDataSource.ADJUST_SKAD_EVENTS,
      ReportDataSource.NETWORKS_SKAD,
    ],
    SPEND: () => [
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.ADJUST_ATTRIBUTION,
    ],
    SPEND_HYPER: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.STORES_INSTALLS,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    SPEND_MINI: () => [ReportDataSource.NETWORKS_SPEND],
    TIME_SPENT: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
    ],
    TRAFFIC_QUALITY: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
      ReportDataSource.NETWORKS_SPEND,
    ],
    WATERFALL: () => [ReportDataSource.NETWORKS_REVENUE],
    MONETIZATION_MONITORING: () => [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_REVENUE,
    ],
    FIREBASE_SHOW_TO_IMPRESSION: () => [ReportDataSource.FIREBASE_EVENTS],
    FIREBASE_VS_NETWORKS: () => [
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.FIREBASE_EVENTS,
    ],
    FIREBASE_FILL_RATE: () => [ReportDataSource.FIREBASE_EVENTS],
    UA_MAIN_METRICS_OVERVIEW: () => [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ...((report as UaMainMetricsOverviewFilter).adRevenueMethod ===
        AdRevenueMethod.AVERAGE &&
      (report as UaMainMetricsOverviewFilter).providedBanner ===
        ProvidedBannerType.ADJUST
        ? [ReportDataSource.NETWORKS_REVENUE, ReportDataSource.STORES_REVENUE]
        : []),
      ...((report as UaMainMetricsOverviewFilter).adRevenueMethod ===
        AdRevenueMethod.PRICED &&
      (report as UaMainMetricsOverviewFilter).providedBanner ===
        ProvidedBannerType.ADJUST
        ? [ReportDataSource.STORES_REVENUE]
        : []),
      ...((report as UaMainMetricsOverviewFilter).adRevenueMethod ===
        AdRevenueMethod.PRICED &&
      (report as UaMainMetricsOverviewFilter).providedBanner ===
        ProvidedBannerType.FIREBASE
        ? [
            ReportDataSource.STORES_REVENUE,
            ReportDataSource.FIREBASE_RAW_EVENTS,
          ]
        : []),
    ],
    SUBSCRIPTIONS_OVERVIEW: () => [
      ReportDataSource.STORES_REVENUE,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
    ],
    METRICS_CONSTRUCTOR: () =>
      getMetricsConstructorDataSources(
        (report as MetricsConstructorFilter).metrics
      ),
    SUBSCRIPTIONS_OVERVIEW_IOS: () => [
      ReportDataSource.STORES_REVENUE,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
    ],
    EVENTS_SUMMARY_LITE: () => [ReportDataSource.ADJUST_RAW_EVENTS],
  }[report.reportId]();
};

const getMetricsConstructorDataSources = (
  metrics: Array<PredefinedMetricModel | UserMetricModel>
) => {
  const types = metrics.reduce(
    (
      result: Array<ReportMetricType | BasicMetricType>,
      metric: PredefinedMetricModel | UserMetricModel
    ) => {
      if (metric.type === MetricType.PREDEFINED) {
        result.push(metric.id as ReportMetricType);
      } else {
        result.push(
          ...(metric as UserMetricModel).definition.basicMetrics.map(
            (item) => item.filter.type as BasicMetricType
          )
        );
      }

      return result;
    },
    []
  );
  const dataSources: Record<
    ReportMetricType | BasicMetricType,
    Array<ReportDataSource>
  > = {
    INSTALLS_COUNT_ADJUST: [ReportDataSource.ADJUST_ATTRIBUTION],
    INSTALLS_COUNT_NETWORK: [ReportDataSource.NETWORKS_SPEND],
    SPEND: [ReportDataSource.NETWORKS_SPEND],
    CPI_ADJUST: [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.NETWORKS_SPEND,
    ],
    CPI_NETWORK: [ReportDataSource.NETWORKS_SPEND],
    CPS: [ReportDataSource.ADJUST_RAW_EVENTS, ReportDataSource.NETWORKS_SPEND],
    SUBSCRIPTION_COUNT: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
    ],
    SUBSCRIPTION_USERS_COUNT: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
    ],
    IN_APP_PURCHASE_COUNT: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
    ],
    IN_APP_PAYING_USERS_COUNT: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
    ],
    AVERAGE_SUBSCRIPTION: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
    ],
    AVERAGE_PURCHASE: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
    ],
    CONVERSION_TO_SUBSCRIPTION_USER: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.STORES_REVENUE,
    ],
    CONVERSION_TO_IN_APP_PAYING_USER: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
    ],
    ROAS_SUBSCRIPTION: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
      ReportDataSource.NETWORKS_SPEND,
    ],
    ROAS_IN_APP: [
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.ADJUST_RAW_EVENTS,
    ],
    ROAS_SUBSCRIPTION_IN_APP: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.ADJUST_RAW_EVENTS,
    ],
    REVENUE: getRevenueDataSourcesForMetricsConstructor(metrics),
    REVENUE_SUBSCRIPTION: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
    ],
    REVENUE_IN_APP: [ReportDataSource.ADJUST_RAW_EVENTS],
    REVENUE_SUBSCRIPTION_IN_APP: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
    ],
    REVENUE_EVENTS_COUNT: [ReportDataSource.ADJUST_RAW_EVENTS],
    REVENUE_ACTIVE_USERS_COUNT: [ReportDataSource.ADJUST_RAW_EVENTS],
    ARPPU: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.STORES_REVENUE,
    ],
    CAC: [
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.STORES_REVENUE,
    ],
    RETURN_RATE: [
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS,
    ],
    ARPDAU_DAILY: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    ARPDAU_COHORT: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    PLAY_TIME_CUMULATIVE: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
    ],
    PLAY_TIME: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
    ],
    TIME_SPENT: getTimeSpentDataSourcesForMetricsConstructor(metrics),
    TIME_SPENT_CUMULATIVE: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
    ],
    REVENUE_COHORT: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    REVENUE_DAILY: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    REVENUE_AD: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_REVENUE,
    ],
    ROAS: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    ROAS_AD: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.NETWORKS_SPEND,
      ReportDataSource.NETWORKS_REVENUE,
    ],
    ARPU: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    ARPU_CUMULATIVE: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.NETWORKS_REVENUE,
      ReportDataSource.STORES_REVENUE,
    ],
    CONVERSION_TO_SUBSCRIPTION_IN_APP_PAYING_USER: [
      ReportDataSource.ADJUST_RAW_EVENTS,
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.STORES_REVENUE,
    ],
    DAILY_ACTIVE_USERS_COUNT: [ReportDataSource.ADJUST_RAW_EVENTS],
    LIFE_TIME_VALUE: [],
    LIFE_TIME_VALUE_PER_USER: [],
    CPM: [],
    CR: [],
    IR: [],
    CTR: [],
    IMPRESSIONS: [],
    CLICKS: [],
    PREDICTED_REVENUE: [],
  };

  return uniq(
    types.reduce(
      (
        res: Array<ReportDataSource>,
        type: ReportMetricType | BasicMetricType
      ) => {
        return res.concat(dataSources[type]);
      },
      []
    )
  );
};

const getTimeSpentDataSourcesForMetricsConstructor = (
  metrics: Array<PredefinedMetricModel | UserMetricModel>
) => {
  const hasPredefinedTimeSpentMetric = metrics.some(
    (item) =>
      item.type === MetricType.PREDEFINED &&
      item.id === ReportMetricType.TIME_SPENT
  );
  const hasCustomTimeSpentMetric = metrics.some(
    (item) =>
      item.type === MetricType.USER &&
      (item as UserMetricModel).definition.basicMetrics.some(
        (item) => item.filter.type === BasicMetricType.TIME_SPENT
      )
  );
  const result = [];

  if (hasPredefinedTimeSpentMetric) {
    result.push(
      ReportDataSource.ADJUST_ATTRIBUTION,
      ReportDataSource.ADJUST_RAW_EVENTS
    );
  }

  if (hasCustomTimeSpentMetric) {
    result.push(ReportDataSource.ADJUST_RAW_EVENTS);
  }

  return uniq(result);
};

const getRevenueDataSourcesForMetricsConstructor = (
  metrics: Array<PredefinedMetricModel | UserMetricModel>
) => {
  const revenueMetrics = metrics.reduce(
    (result: Array<RevenueMetricFilterModel>, metric) => {
      if (metric.type === MetricType.USER) {
        result.push(
          ...((metric as UserMetricModel).definition.basicMetrics.filter(
            (item) => item.filter.type === BasicMetricType.REVENUE
          ) as Array<any>)
        );
      }

      return result;
    },
    []
  );
  const hasAdRevenuePricedMethod = revenueMetrics.some(
    (item: RevenueMetricFilterModel) =>
      item.revenueMethod === AdRevenueMethod.PRICED
  );
  const hasAdRevenueAverageMethod = revenueMetrics.some(
    (item: RevenueMetricFilterModel) =>
      item.revenueMethod === AdRevenueMethod.AVERAGE
  );
  const revenueTypes = uniq(
    revenueMetrics.map((item) => item.revenueTypes).flat()
  );
  const bannerProviders = uniq(
    revenueMetrics.map((item) => item.bannerProvider)
  );
  const result = [ReportDataSource.ADJUST_RAW_EVENTS];

  if (hasAdRevenuePricedMethod) {
    if (revenueTypes.some((item) => ["IAPS", "SUBSCRIPTION"].includes(item))) {
      result.push(ReportDataSource.STORES_REVENUE);
    }

    if (bannerProviders.includes(ProvidedBannerType.FIREBASE)) {
      result.push(ReportDataSource.FIREBASE_RAW_EVENTS);
    }
  }

  if (hasAdRevenueAverageMethod) {
    if (revenueTypes.some((item) => ["IAPS", "SUBSCRIPTION"].includes(item))) {
      result.push(ReportDataSource.STORES_REVENUE);
    } else {
      result.push(ReportDataSource.NETWORKS_REVENUE);
    }
  }

  return uniq(result);
};
